var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-btn", { on: { click: _vm.CreateTN } }, [_vm._v(" Create Tenant ")]),
      _c("v-btn", { on: { click: _vm.EditTN } }, [_vm._v(" Edit Tenant ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }